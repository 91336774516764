// State
export const state = () => ({
	announcements: [],
	announceCancelToken: {}
})

// Actions
export const actions = {
	async announcements({ commit, state }, params) {
		commit('setAnnounceCancelToken', { announcements: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${this.$config.FRONTEND_DOMAIN}/cms/bo/announcements`, { params, cancelToken: state.announceCancelToken.announcements.token })
			.then((response) => {
				commit('announcements', response?.data)
			})
			.catch(() => false)
	},

	async createAnnouncement({ commit }, data) {
		commit('setLoading', true)
		const response = await this.$axios
			.post(`${this.$config.FRONTEND_DOMAIN}/cms/bo/announcements`, data)
			.then((response) => {
				commit('setLoading', false)
				return true
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
		return response
	},
	async update({ commit }, data) {
		const { form, } = { ...data }
		const id = data.id
		commit('setLoading', true)
		const response = await this.$axios
			.put(`${this.$config.FRONTEND_DOMAIN}/cms/bo/announcements/${id}`, form)
			.then((response) => {
				return true
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
		return response
	},
	async delete({ rootGetters }, id) {
		const response = await this.$axios
			.delete(`${this.$config.FRONTEND_DOMAIN}/cms/bo/announcements/${id}`)
			.then((response) => {
				this.$toast.success(rootGetters['master/storeTl']('deleteSuccessful'))

				return true

			})
			.catch(() => {
				return false
			})
		return response
	},

}

// Mutations
export const mutations = {
	announcements(state, response) {
		state.announcements = response
	},
	setComponent(state, data) {
		state.component = data
	},
	setLoading(state, data) {
		state.loading = data
	},
	setAnnounceCancelToken: (state, data) => {
		const oldData = { ...state.announceCancelToken }
		state.announceCancelToken = {
			...oldData, ...data
		}
	}

}

// Getters
export const getters = {

}
