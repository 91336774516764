// State
export const state = () => ({
	user: {},
	loading: false
})

// Actions
export const actions = {
	async login({ commit, rootGetters }, userData) {
		try {
			commit('setLoading', true)
			return await this.$axios
				.post(`/affiliates/login`, userData)
				.then((response) => {
					const responseData = response.data
					responseData.token = response.headers.token

					commit('setLogin', responseData)
					this.$toast.success(rootGetters['language-strings/storeTl']('loggedInWelcomeBoard'))

					// Local storing
					this.$cookie.remove('token')
					this.$cookie.set('affiliateToken', responseData.token, {
						path: "/",
						maxAge: 60 * 60 * 24 - 60,
					})
					this.$cookie.set('affiliateUser', responseData, {
						path: "/",
						maxAge: 60 * 60 * 24 - 60,
					})
					commit('setLoading', false)
					this.$router.push(('/agent/affiliate'))
					return true
				})
		} catch (error) {
			commit('setLoading', false)
			this.$toast.error(error)
		}
	},

	async logout({ rootGetters }) {
		try {
			await this.$axios.get('/affiliates/session/logout');
			this.$cookie.remove('affiliateToken')
			this.$toast.info(rootGetters['language-strings/storeTl']('logoutSeeYou'))
			this.$router.push('/agent')
		} catch (error) {
		}
	},
}

// Mutations
export const mutations = {
	setLogin(state, response) {
		state.user = response
	},
	setLoading(state, type) {
		state.loading = type
	}
}

// Getters
export const getters = {}
