// State
export const state = () => ({
	loading: false,
	languages: [],
})

// Actions
export const actions = {
	// ? Languages
	// Get
	async languages({ commit }, params) {
		commit('loading', true)

		let isActive = ''
		if (params?.is_active === false) {
			isActive = ``
		} else {
			isActive = '?is_active=true'
		}
		await this.$axios
			.get(`/agents/languages${isActive}`)
			.then((response) => {
				commit('setLanguages', response.data)
				commit('loading', false)
			})
			.catch(() => false)
		commit('loading', false)
	},
}

// Mutations
export const mutations = {
	setLanguages: (state, v) => {
		state.languages = v
	},
	loading: (state, type) => {
		state.loading = type
	},
}

// Getters
export const getters = {
}
