import Router from 'vue-router'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
};

async function checkIfItsMain(backendBaseApi, requestOrigin) {
	const apiUrl = `${backendBaseApi}/white-label/default`;
	try {
		const headers = { 'Origin': requestOrigin }
		const response = await fetch(apiUrl, {
			headers
		});
		if (response.ok) {
			const data = await response.json();

			// If it's MAIN return true
			if (data?.domain_type && data?.domain_type?.toLowerCase() === 'main') {
				// Remove is agent cookie, in case the website is main website
				document.cookie = 'ia=;expires=Thu, 01 Jan 1970 00:00:00 GMT;'
				return true; // If you want to test agent website in localhost, change this into false
			} else if (data?.domain_type?.toLowerCase() === 'agent' && data?.currency) {
				if (process.browser) {
					document.cookie = `currency=${data?.currency}; path=/`
					document.cookie = `ia=true; path=/`
				}
			}
			// For development
			// if (process.browser) {
			// 	document.cookie = `currency=USDT; path=/`
			// 	document.cookie = `ia=true; path=/`
			// }
			return false;
		}
		return true;
	} catch (error) {
		return true;
	}
}


export async function createRouter(ssrContext, createDefaultRouter, routerOptions, config) {
	const options = routerOptions || createDefaultRouter(ssrContext).options
	const currentHost = ssrContext?.req?.headers?.host
	// read and understand on README.md file
	let url = config.SERVER_URL;
	if (process.client) {
		url = config.API_DOMAIN
	}
	const isMainHost = await checkIfItsMain(url, currentHost)

	let routesDirectory = null

	if (process.server && ssrContext && ssrContext.nuxt && ssrContext.req) {
		const req = ssrContext.req
		routesDirectory = req.url.split('/')[1]

		// Save to the object that will be sent to the client as inline-script
		ssrContext.nuxt.routesDirectory = routesDirectory
	}

	if (process.client) {
		// Get what we saved on SSR
		if (window.__NUXT__ && window.__NUXT__.routesDirectory) {
			routesDirectory = window.__NUXT__.routesDirectory
		}
	}

	const availableRoutes = options.routes

	const newRoutes = []
	for (const route of availableRoutes) {
		const affiliateRootPath = 'affiliate_root_path'
		const checkAffiliateRouter = route.path.startsWith('/' + affiliateRootPath)
		const agentRootPath = 'agent_root_path'
		const checkAgentRouter = route.path.startsWith('/' + agentRootPath)
		if (isMainHost) {
			if (!(checkAgentRouter || checkAffiliateRouter)) {
				newRoutes.push({
					name: route.name,
					path: route.path,
					component: route.component
				})
			}
			continue
		}
		if (checkAgentRouter) {
			newRoutes.push({
				name: route.name,
				path: route.path.replace('/' + agentRootPath, ""),
				component: route.component
			})
		} /* else if (checkAffiliateRouter) {
			newRoutes.push({
				name: route.name,
				path: route.path.replace('/' + affiliateRootPath, ""),
				component: route.component
			})
		} */

	}
	if (!isMainHost) {
		newRoutes.push({ name: 'Unknown', path: "*", redirect: '/error404' })

	}
	const router = new Router({
		...options,
		routes: newRoutes
	})
	return router

}