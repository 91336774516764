// State
export const state = () => ({
	affiliates: {},
	loading: false,
})

// Actions
export const actions = {
	async affiliates({ commit }, params) {
		if (params.status === '') delete params.status
		if (params.name === '') delete params.name
		if (params.currency === '') delete params.currency

		commit("setLoading", true)
		if (params.status === '') delete params.status
		await this.$axios.get('/admin-affiliates', { params })
			.then((response) => {
				commit("setAffiliates", response.data)

			}).catch(() => false)
		commit("setLoading", false)
	},

	async createAffiliate({ commit }, form) {
		commit("loading", true)
		const response = await this.$axios.post('/admin-affiliates', form)
			.then((response) => {
				commit('loading', false)
				return response.data;
			}).catch(() => false)
		return response
	},
	async updateAffiliate({ dispatch }, form) {
		const id = form.id;
		delete form.id

		const updateForm = {}

		// ? Since the UPDATE API has only 5 of these are available

		if (form.name) updateForm.name = form.name;
		if (form.gender) updateForm.gender = form.gender;
		if (form.phone) updateForm.phone = form.phone;
		if (form.status || form.status === 0) updateForm.status = form.status;
		if (form.password) updateForm.password = form.password;

		const response = await this.$axios.put(`/admin-affiliates/${id}`, updateForm)
			.then(() => {
				return true
			}).catch(() => false)
		return response
	},
	async deleteAffiliate({ dispatch }, id) {
		const response = await this.$axios.delete(`/admin-affiliates/${id}`)
			.then(() => {
				dispatch('affiliates')
				return true
			}).catch(() => false)
		return response
	},
	async resetPassword({ commit }, id) {
		commit("setLoading", true)
		return await this.$axios.put(`/admin-affiliates/password/random/${id}`)
			.then((response) => {
				commit("setLoading", false)
				return response.data;
			}).catch(() => { commit('setLoading', false); return false });
	},
}

// Mutations
export const mutations = {
	setAffiliates(state, type) {
		state.affiliates = type;
	},
	setLoading(state, type) {
		state.loading = type
	},

}