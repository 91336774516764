// State
export const state = () => ({
	loading: false,
	banks: [],
	playerBanks: {}
})

// Actions
export const actions = {
	// Get all bank list
	async banks({ commit }, params) {
		commit('setBanks', [])
		try {
			commit('loading', true)
			await this.$axios
				.get(`/affiliates/bank-list`, { params })
				.then((response) => {
					commit('setBanks', response.data.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async playerBanks({ commit }, params) {
		commit('playerBanks', [])
		try {
			commit('loading', true)
			await this.$axios
				.get(`/affiliates/bank-account`, { params })
				.then((response) => {
					commit('playerBanks', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async createBank({ commit }, form) {
		commit('loading', true)
		try {
			const response = await this.$axios
				.post(`/affiliates/bank-account`, form)
				.then((response) => {
					commit('loading', false)
					return true
				})
			return response
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},

}

// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setBanks(state, data) {
		state.banks = data
	},
	playerBanks(state, data) {
		state.playerBanks = data
	},
}

// Getters
export const getters = {}
