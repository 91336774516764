// State
export const state = () => ({
	agent: {},
	agents: { data: [] },
	loading: false,
	agentReport: {
		"total": 0,
		"totalPages": 0,
		"total_bet": 0,
		"turnover": 0,
		"win_loss": 0,
		"turnover_com": 0,
		"player_loss_com": 0,
		"risky_sharing_com": 0,
		"commission_fee": 0,
		"data": []
	},
	// affiliates: [],
	dnsZone: [],
	agentSettlements: [],
	agentCancelToken: {}
})

// Actions
export const actions = {
	async agent({ commit, state }, id) {
		commit('setAgentCancelToken', { agent: this.$axios.CancelToken.source() })
		commit("setAgent", {})
		commit("setLoading", true)
		await this.$axios.get(`/admin-agents/${id}`, {
			cancelToken: state.agentCancelToken.agent.token
		})
			.then((response) => {
				commit("setAgent", response.data)

			}).catch(() => false)
		commit("setLoading", false)
	},
	async agents({ commit, state }, params) {
		commit('setAgentCancelToken', { agents: this.$axios.CancelToken.source() })
		if (params.status === '') delete params.status
		if (params.name === '') delete params.name
		if (params.currency === '') delete params.currency

		commit("setLoading", true)
		await this.$axios.get('/admin-agents', {
			params,
			cancelToken: state.agentCancelToken.agents.token
		})
			.then((response) => {
				commit("setAgents", response.data)

			}).catch(() => false)
		commit("setLoading", false)
	},

	async createAgent({ commit }, form) {
		const response = await this.$axios.post('/admin-agents', form)
			.then((response) => {
				return response.data;
			}).catch(() => false)
		return response
	},
	async updateAgent({ dispatch }, form) {
		const response = await this.$axios.put(`/admin-agents/${form.id}`, form.body)
			.then((data) => {
				return data.data
			}).catch(() => false)
		return response
	},
	async deleteAgent({ dispatch }, id) {
		const response = await this.$axios.delete(`/admin-agents/${id}`)
			.then(() => {
				dispatch('agents')
				return true
			}).catch(() => false)
		return response
	},
	async agentReports({ commit, state }, params) {
		commit('setAgentCancelToken', { agentReports: this.$axios.CancelToken.source() })
		const agentUUid = params.agent_uuid
		if (!params.player_id) delete params.player_id
		delete params.agent_uuid
		delete params.search
		commit("setLoading", true)
		await this.$axios.get(`/admin-agents/players/${agentUUid}`, {
			params,
			cancelToken: state.agentCancelToken.agentReports.token
		})
			.then((response) => {
				commit('setAgentReport', response.data)
				commit("setLoading", false)
			}).catch(() => {
				commit("setLoading", false)
			});

	},
	async resetPassword({ commit }, id) {
		commit("setLoading", true)
		return await this.$axios.put(`/admin-agents/password/random/${id}`)
			.then((response) => {
				commit("setLoading", false)
				return response.data;
			}).catch(() => { commit('setLoading', false); return false });
	},
	async dnsZone({ commit }, form) {
		const { domain, username } = form
		// reset all data before getting new data
		commit('setDnsZone', [])
		const response = await this.$axios.get(`/admin-agents/dns-zone/${domain}/${username}?purpose=1`)
			.then((response) => {
				commit('setDnsZone', response.data)
				return true;
			})
			.catch(() => {
				return false
			});
		return response
	},
	async agentSettlements({ commit, state }, params) {
		commit('setAgentCancelToken', { agentSettlements: this.$axios.CancelToken.source() })
		commit("setLoading", true)
		await this.$axios.get(`/admin-agents/settlements/report`, {
			params,
			cancelToken: state.agentCancelToken.agentSettlements.token
		})
			.then((response) => {
				commit("setAgentSettlements", response.data)

			}).catch(() => false)
		commit("setLoading", false)
	},
	async updateSettlementStatus({ commit }, params) {
		const response = await this.$axios.put(`/admin-agents/settlements/settle/${params.id}/${params.month}`, {})
			.then((response) => {
				return true

			}).catch(() => {
				return false
			})
		return response
	},

}

// Mutations
export const mutations = {
	setAgents(state, response) {
		state.agents = response
	},
	setLoading(state, type) {
		state.loading = type
	},
	setAgentReport(state, val) {
		state.agentReport = val;
	},
	setAgent(state, response) {
		state.agent = response
	},
	setDnsZone(state, response) {
		state.dnsZone = response
	},
	setAgentSettlements(state, response) {
		state.agentSettlements = response
	},
	setAgentCancelToken: (state, data) => {
		const oldData = { ...state.agentCancelToken }
		state.agentCancelToken = {
			...oldData, ...data
		}
	}
}
// Getters
export const getters = {
	filterAgentReport: (state) => (type) => {
		return state.agentReport.length > 0
			? state.agentReport.data.filter((object) => object.user_type === type)
			: []
	},
}
