// State
export const state = () => ({
	payoutDetail: {}
})

// Actions
export const actions = {
	async providerBetDetail({ _ }, id) {
		const response = await this.$axios
			.get(`/bet-management/bet-detail/provider-bet-detail/${id}`)
			.then((response) => {
				const data = response.data
				if (data.hasRedirectLink) {
					window.open(data.link, '_blank')
				}
				return true
			})
			.catch((e) => {
				return false
			})
		return response
	},
	async cancelBet({ _ }, id) {
		const response = await this.$axios
			.put(`/bet-management/bet-history/cancel/${id}`)
			.then((response) => {
				return true
			})
			.catch((e) => {
				return false
			})
		return response
	},
	async resendBet({ _ }, id) {
		const response = await this.$axios
			.put(`/bet-management/bet-history/resend/${id}`)
			.then((response) => {
				return true
			})
			.catch((e) => {
				return false
			})
		return response
	},
	async payoutDetail({ commit }, id) {
		commit('payoutDetail', {})
		await this.$axios
			.get(`/bet-management/bet-detail/bet-detail-1/${id}`)
			.then((response) => {
				let obj = {}
				const data = response.data?.sports_detail
				obj =
					data?.sub_bets && data?.sub_bets[0]
						? { ...data?.sub_bets[0] }
						: {}
				delete data?.sub_bets
				obj = { ...obj, ...data }
				commit('payoutDetail', obj)
			})
			.catch((e) => {
			})
	},
}

// Mutations
export const mutations = {
	payoutDetail(state, type) {
		state.payoutDetail = type
	},
}